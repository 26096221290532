<template>
  <div class="course-details">
    <!-- <div class="cd-banner">
      <vx-card
        :card-background="
          'linear-gradient(120deg ,rgba(0,0,0,.6), rgba(0,0,0,.6)), url(' +
            course_details_bg_img +
            ')'
        "
        style="background-position:center; background-size: cover;"
      >
        <div class="vx-row" v-for="(service, index) in services" :key="index" >
          <div class="vx-col w-full">
            <div class="cd-banner-heading">
              <h2 class="text-white">{{ serviceTitle }}</h2>
            </div>
          </div>
          <div class="vx-col w-full md:w-1/4 lg:w-1/6">
            <h5 class="text-white font-semibold">Educator</h5>
            <p class="text-white">Cindy Nicholson</p>
          </div>
          <div class="vx-col w-full md:w-1/4 lg:w-1/6">
            <h5 class="text-white font-semibold">Category</h5>
            <p class="text-white">{{ service.category }}</p>
          </div>
          <div class="vx-col w-full md:w-1/4 lg:w-1/4">
            <h5 class="text-white font-semibold">Topics</h5>
            <span class=""
              >{{ service.subjects[0] }},{{ service.subjects[1] }},{{
                service.subjects[2]
              }},{{ service.subjects[3] }}</span
            >
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col w-full mt-4">
            <div class="d-flex">
              <div class="d-flex text-warning">
                <span class="mr-1">4.5</span>
                <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />
              </div>
              <span class="ml-2">(60 reviews)</span>
            </div>
          </div>
        </div>
      </vx-card>
    </div> -->

    <div class="">
      <vx-card  class="mt-base">
        <div class="vx-row">
          <div v-if="sessionBooking.imageURL" class="vx-col w-full lg:w-1/2 md:pr-5">
            <img
              :src="sessionBooking.imageURL[0]"
              alt="content-img"
              class="responsive rounded-lg sd-image"
            />
          </div>
          <div class="vx-col w-full lg:w-1/2">
            <div class="mt-3 md:mt-base">
              <!-- <div class="flex justify-end">
                <div class="reviews-ratings mt-5 mb-5">
                  <div class="d-flex">
                    <div class="d-flex text-warning">
                      <span class="mr-1">4.5</span>
                      <feather-icon icon="StarIcon" svgClasses="h-4 w-4" />
                    </div>
                    <span class="ml-2">(60 reviews)</span>
                  </div>
                </div>
              </div> -->
              <h3>{{sessionBooking.title}}</h3>
              
              <div class="mt-4">
                <p class="font-semibold lead">Service offered:</p>
                 <div
                    v-for="(serviceOffered, index) in sessionBooking.servicesOffered"
                    :key="index"
                  >                  
                <span>{{serviceOffered}}</span>
                 </div>
                 <div class="mt-4">
                <p class="font-semibold lead">Category:</p>
                 <div
                    
                  >                  
                <span>{{sessionBooking.category}}</span>
                 </div>
              </div>
              </div>
              <div class="mt-4">
                <p class="font-semibold lead">Topics:</p>
                <div class="flex flex-wrap mt-2">
                  <div class="rounded-full bg-custom-light px-3 py-2 mr-3"
                  v-for="(subjects, index) in sessionBooking.subjects"
                    :key="index">
                    <span class="">{{subjects}}</span>
                  </div>
                  
                </div>
              </div>
              <div class="mt-5 md:flex justify-between">
                <div v-if="sessionBooking.packages" class="" >
                  <span class="font-semibold text-success h4">${{sessionBooking.packages.items[0].discountPrice}}</span>
                  <span
                    class="text-primary ml-2 h4"
                    style="text-decoration: line-through"
                    >${{sessionBooking.packages.items[0].price}}</span
                  >      
                </div><br>
              </div>
              <div class="mt-5 md:flex items-center justify-between">
                <vs-button
                  color="primary"
                  type="filled"
                  @click="viewService(sessionBooking)"
                  >Book Now
                  </vs-button
                >
                <div class="d-flex items-center mt-base md:mt-0">
                  <span class="font-semibold mr-3">SHARE:</span>
                  <div class="share-network-list">
                  <ShareNetwork
    v-for="network in networks"
    :network="network.network"
    :key="network.network"
    :style="{backgroundColor: network.color}"
    :url="url"
    :title="sharing.title"
    :description="sharing.description"
    :quote="sharing.quote"
    :hashtags="sharing.hashtags"
    :twitterUser="sharing.twitterUser"
  >
    <feather-icon :icon="network.icon"></feather-icon>
  </ShareNetwork>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>

    <div class="">
      <div class="vx-row">
        <div class="vx-col w-full sm:w-2/3 md:w-full lg:w-2/3 mt-base">
         <vx-card class="mb-3">
            <div class="">
              <h5>Description</h5>
              <hr />
            </div>
            <div v-html="sessionBooking.description" class="mt-5 about-coursefeeds">
            <!-- {{ courseFeed.description }} -->
            {{ sessionBooking.description }}
            </div>
          </vx-card>
           
          <!-- <vx-card class="">
            <div class="">
            <div class="flex justify-between">
                <h5 class="mb-0">Reviews</h5>
               <vs-button
                  @click="show_pop_up = true"
                  color="primary"
                  type="filled"
                  size="small"
                  >Add Review</vs-button
                >   
              </div> 
         
              <div id="lms">
                <vs-popup title="Write a review" :active.sync="show_pop_up">
                  <div class="p-4">
                    <review-pop-up />
                  </div>
                </vs-popup>
              </div>
            </div>
          </vx-card> -->
        </div>
        <div class="vx-col w-full sm:w-1/3 md:w-full lg:w-1/3 mt-base">
          <vx-card class="">
            <div class="flex justify-between">
              <h5>Educator</h5>
              <div class="">
                    <vs-button
              color="primary"
              icon-pack="feather"
              icon="icon-message-square"
              class=""
              size="small"
               @click="goToChatRoom()"
              >Message Me</vs-button
            >
              </div>
            </div>
            <hr />
            <div class="vx-row mt-5">
              <div class="vx-col w-full md:w-1/4 lg:w-1/4">
                <img
                  :src="sessionCreatedUser.photoURL"
                  alt="content-img"
                  class="responsive rounded-lg"
                />
              </div>
              <div class="vx-col w-full md:w-3/4 lg:w-3/4 mt-3 md:mt-0">
                <div v-if="sessionBooking.educatorRef" class="mb-3">
                  <div class="">
                     <h5><a @click="bindUrl(sessionBooking.educatorRef)" class="cursor-pointer text-primary">{{sessionBooking.educatorRef.user}}</a></h5>
                  
                  <span class="text-grey">Educator</span><br>
                  <span >{{sessionBooking.educatorRef.profession}}</span>
                  <div class="mt-1"><span class="font-semibold mr-2">About :</span>{{ sessionCreatedUser.about }}</div>
              
                </div>                 
                </div>
  
              </div>
            </div>
          </vx-card>
           <!--
          <vx-card class="mt-base">
            <div class="">
              <h5>My Availabilities</h5>
              <hr />
            </div>
            <div class="vx-row mt-5 justify-center">
            <div class="vx-col sd-datepicker">
              <datepicker :inline="true" :highlighted='state.highlighted'></datepicker>
            </div>
            </div>
          </vx-card>
           -->

        </div>
      </div>
        </div>
    <view-service-popup ref="viewPopup"  />
           
  </div>
</template>

<script>
import { API, graphqlOperation } from "@aws-amplify/api";
import {
  sessionBookingByServiceId,
  getEducatorService,
  getUser
} from "@/graphql/queries";
import { Storage } from "@aws-amplify/storage";
import ViewServicePopup from './ViewServicePopup.vue'
import { createErrorObject } from "@/utils";
import vSelect from "vue-select";

import ReviewPopUp from "@/layouts/components/coursepal/ReviewPopUp";
s
import VueSocialSharing from 'vue-social-sharing'
export default {
  data() {
    return {
      course_details_bg_img: require("@/assets/images/pages/sd-banner.jpg"),
      services: [],
      addReview: false,
      show_pop_up: false,
      textarea: "",
      serviceTitle:'',
      url: 'https://coursepal.app/',
       state:{
                    highlighted:{
                      to: new Date(2021, 7, 10),
    from: new Date(2021, 7, 6)
                    }
                  },
      
      options: [
        {
          id: 1,
          label: "5 stars"
        },
        {
          id: 2,
          label: "4 stars"
        },
        {
          id: 3,
          label: "3 stars"
        },
        {
          id: 4,
          label: "2 stars"
        },
        {
          id: 5,
          label: "1 star"
        }
      ],
      sharing: {
        url: 'https://coursepal.app/',
        title: 'Join Coursepal & book one-one learning sessions.',
        description: 'Coursepal is a social networking app to meet like-minded people and educators.',
        quote: 'Learn Socially!',
        hashtags: 'coursepal,learningnetwork,learningapp',
        twitterUser: 'coursepal'
      },
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'FacebookIcon', color: '#1877f2' },
        { network: 'linkedin', name: 'LinkedIn', icon: 'LinkedinIcon', color: '#007bb5' },
        { network: 'twitter', name: 'Twitter', icon: 'TwitterIcon', color: '#1da1f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'MessageCircleIcon', color: '#25d366' },
      ],
      selected: {
        id: 1,
        label: "5 stars"
      },
      sessionBookingID : '',
      sessionBooking : {},
      sessionCreatedUser : {},
    };
  },
  components: {
    "v-select": vSelect,
    // AddReviewPopup,
    ReviewPopUp,
  
    Datepicker,
     ViewServicePopup,
     VueSocialSharing
  },
  
  created() {
    this.sessionBookingID=this.$route.params.id
    this.fetchDetails();
  },
  mounted() {
    this.$store.commit("TOGGLE_REDUCE_BUTTON", true);
  },
  methods: {
    goToChatRoom () {
      this.$router.push(`/chat/${this.sessionBooking.educatorRef.user}-${this.$store.state.auth.userData.name}`)
    },
       bindUrl (value) {
     
       // return `partner/${value.name}`
        this.$router.push(`/partner/${value.user}`)
    
     
    },
     async viewService (service) {
      try {
        this.$vs.loading()
        const viewPopup = this.$refs.viewPopup
        const disabledDates = []
        const days = service.schedule.map(item => item.day)
        for (let i = 0; i < 7; i++) {
          if (!days.includes(i)) {
            disabledDates.push(i) 
          }
        }
        viewPopup.disabledDates.days = disabledDates
        viewPopup.schedule = service.schedule
  
        // create timeslot      
        viewPopup.session = service.session
        service.packages.items.map(item => { item.isSelected = false })
        viewPopup.packages = service.packages.items
        viewPopup.selectPackage = service.packages.items[0]
        viewPopup.serviceID = service.id
        viewPopup.timeslots = []
        const getUserRes = await API.graphql(
              graphqlOperation(getUser, {
                name: service.educatorRef.user,
              })
        );
        viewPopup.serviceUserData = getUserRes.data.getUser;
        const bookingRes = await API.graphql(graphqlOperation(sessionBookingByServiceId, {
          educatorServiceID: service.id
        }))
        viewPopup.bookings = bookingRes.data.sessionBookingByServiceID.items
        viewPopup.active = true
        this.$vs.loading.close()
      } catch (err) {
        console.log(err)
      }
    },
     async fetchDetails() {
      try {
        this.$vs.loading()
         const getEducatorServiceRes = await API.graphql(
              graphqlOperation(getEducatorService, {
                id: this.$route.params.id,
              })
            );
            const service = getEducatorServiceRes.data.getEducatorService;
            service.imageURL = [];
            for (const img of service.images) {
              const url = await Storage.get(img);
              service.imageURL.push(url);
            }
            this.sessionBooking = service;
            this.fetchSessionCreatedUserDetails(service.educatorRef.user);
            this.url = `https://coursepal.app/service-details/${this.sessionBooking.id}`
      } catch (err) {
        console.log(err);
      }
    },
     async fetchSessionCreatedUserDetails(name) {
      try {
        this.$vs.loading()
        const getUserRes = await API.graphql(
          graphqlOperation(getUser, {
            name: name,
          })
        );
        const user = getUserRes.data.getUser;
        this.sessionCreatedUser = user;
        this.$vs.loading.close()
      } catch (err) {
        console.log(err);
      }
    },
  }
};
</script>

<style lang="scss">
.bg-custom-light {
  margin-right: 5px !important;
  margin-bottom: 8px !important;
  font-size: 13px !important;
  line-height: 1.1 !important;
  padding: 4px 8px !important;
  // font-weight: 600 !important;
  color: rgb(53, 64, 83) !important;
  background-color: rgb(228, 234, 240) !important;
  border-radius: 4px !important;
  word-break: break-word;
}
.share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
  }
  a[class^="share-network-"] {
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
    margin: 0 10px 0px 0;
  }
  
  // a[class^="share-network-"] .fah {
  //   background-color: rgba(0, 0, 0, 0.2);
  //   padding: 10px;
  //   flex: 0 1 auto;
  // }
  a[class^="share-network-"] .feather-icon {
    // background-color: rgba(0, 0, 0, 0.2);
    // padding: 10px;
    padding: 5px;
    flex: 0 1 auto;
  }
  a[class^="share-network-"] span {
    padding: 0 10px;
    flex: 1 1 0%;
    font-weight: 500;
  }
@media (min-width: 992px) { 
.sd-datepicker .vdp-datepicker__calendar{
  width: unset;
  }
.sd-datepicker .vdp-datepicker .cell.day{
    height: 51.33px;
    line-height: 51.33px;
    width: 12.285714286%;
  }
  .sd-image{
  height:380px;
  -o-object-fit: cover;
  object-fit: cover;
}
 }
 @media screen and (min-width: 900px) and (max-width: 1440px) {
   .sd-datepicker .vdp-datepicker .cell.day{
    height: 45.47px;
    line-height: 45.47px;
    width: 12.285714286%;
  }
 }
 @media screen and (min-width: 800px) and (max-width: 1280px) {
 }
</style>